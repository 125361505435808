import { Pipe, PipeTransform } from "@angular/core";
import { User } from "../interfaces/users/user";

@Pipe({
	name: "name",
	standalone: true,
})
export class NamePipe implements PipeTransform {
	transform(user: User): string {
		if (user.firstname === "__GROUP__") {
			return user.lastname.charAt(0).toUpperCase() + user.lastname.slice(1).toLowerCase();
		}
		const firstname = user.firstname
			.toLowerCase()
			.split(/[\s-]+/)
			.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
			.join("-");
		const fullname = firstname + " " + user.lastname.toUpperCase();
		return fullname.trim();
	}
}
